import React, { useContext } from "react"
import { ModalVisibility } from "../layout"

const ModalSubmitted = () => {
  const { modalSubmitted, setModalSubmitted } = useContext(ModalVisibility)

  return (
    <div className="modal modal--visible modal-info">
      <div className="modal-container">
        <p>{modalSubmitted.message}</p>
        <div className="modal-row">
          <button
            aria-label='Okej'
            className="controllers-button"
            onClick={() => setModalSubmitted({ visible: false, message: "" })}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalSubmitted
