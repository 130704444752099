import React, { useState } from "react"
import { Link } from "gatsby"
import Services from './Services'

const ExtendedList = ({ reference, url }) => {
  const [showList, setShowList] = useState(false)

  return (
    <li className="navbar-navigation-item navbar-navigation-item-expended">
      <div className="navbar-navigation-item-expended-wrapper">
        <Link aria-label={reference} to={url}>{reference}</Link>
        <button
          aria-label='Lista usług'
          className="expended-links-button"
          onClick={() => setShowList(!showList)}
        >
          <div className="expended-links-button-inner"></div>
        </button>
      </div>
      
      <ol className={`expended-list ${showList && "expended-list--active"}`}>
        <Services />
      </ol>
    </li>
  )
}

export default ExtendedList
