import React, { useContext, useState } from "react"
import { ModalVisibility } from "../layout"
import { Form, Field } from "react-final-form"
import emailjs from "emailjs-com"

const Modal = () => {
  const [valid, setValid] = useState(false)
  const { modalVisibility, setModalVisibility, setModalSubmitted } = useContext(
    ModalVisibility
  )

  const onSubmit = async e => {
    e.preventDefault()

    if (!valid) {
      await setModalSubmitted({
        visible: true,
        message: `Formularz został wypełniony niepoprawnie. Prosimy spróbować ponownie, bądź skorzystać z innej opcji kontaktu.`,
      })
    }

    if (valid) {
      await emailjs
        .sendForm(
          "service_o9pvprq",
          "template_76crnpr",
          e.target,
          "user_tnTl88Ig4gnIchWWxsHBl"
        )
        .then(
          result => {
            setModalSubmitted({
              visible: true,
              message: "Widaomość została wysłana poprawnie.",
            })
          },
          error => {
            setModalSubmitted({
              visible: true,
              message: `${error.text}! Spróbuj skontaktować się z nami w inny sposób, przepraszamy za utrudnienia.`,
            })
          }
        )
    }
  }

  const validate = values => {
    const errors = {}

    if (!values.sendername) {
      errors.sendername = "Pole jest wymagane."
      setValid(false)
    }
    if (!values.email) {
      errors.email = "Podaj swój e-mail."
      setValid(false)
    }
    if (!values.title) {
      errors.title = "Podaj tytuł wiadomości."
      setValid(false)
    }
    if (!values.message) {
      errors.message = "Podaj treść wiadomości."
      setValid(false)
    }

    if (values.message && values.message.length < 30) {
      errors.message =
        "Treść wiadomości jest za krótka, powinna przkraczać 30 znaków."
      setValid(false)
    }

    if (
      values.sendername &&
      values.email &&
      values.title &&
      values.message &&
      values.message.length >= 30
    ) {
      setValid(true)
    }

    return errors
  }

  return (
    <div className={`modal ${modalVisibility ? "modal--visible" : ""}`}>
      <div className="modal-container">
        <button
          aria-label="Zamknij"
          className="modal-button"
          onClick={() => setModalVisibility(false)}
        ></button>
        <h5>Formularz kontaktowy</h5>
        <Form
          onSubmit="submit"
          validate={values => validate(values)}
          render={({ form, submitting, pristine }) => (
            <form
              className="contact-form"
              name="contact"
              onSubmit={e => {
                setModalVisibility(false)
                onSubmit(e)
                form.reset()
              }}
            >
              <div className="form-row">
                <Field name="sendername">
                  {({ input, meta }) => (
                    <div>
                      <label htmlFor="sendername">Imię:</label>
                      <br />
                      <input
                        {...input}
                        type="text"
                        placeholder="Imię"
                        id="sendername"
                        required
                      />
                      {meta.error && meta.touched && (
                        <p className="error">{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <label htmlFor="email">E-mail:</label>
                      <br />
                      <input
                        {...input}
                        type="email"
                        id="email"
                        placeholder="user@email.com"
                        required
                      />
                      {meta.error && meta.touched && (
                        <p className="error">{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-row">
                <Field name="title">
                  {({ input, meta }) => (
                    <div>
                      <label htmlFor="title">Tytuł:</label>
                      <br />
                      <input
                        {...input}
                        type="text"
                        id="title"
                        placeholder="Tytuł"
                        autoComplete="off"
                        required
                      />
                      {meta.error && meta.touched && (
                        <p className="error">{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-row">
                <Field name="message">
                  {({ input, meta }) => (
                    <div>
                      <label htmlFor="message">Wiadomość:</label>
                      <br />
                      <textarea {...input} id="message" required />
                      {meta.error && meta.touched && (
                        <p className="error">{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-row form-buttons">
                <button
                  className="controllers-button"
                  type="submit"
                  disabled={submitting}
                >
                  Wyślij
                </button>
                <button
                  className="controllers-button"
                  disabled={submitting || pristine}
                  onClick={() => {
                    setModalVisibility(false)
                    form.reset()
                  }}
                >
                  Wyczyść
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}

export default Modal
