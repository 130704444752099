import React, { useState } from "react"
import Header from "./header/header"
import Modal from "./mailModal/Modal"
import ModalSubmitted from "./mailModal/ModalSubmitted"
import "../Scss/app.scss"

export const ModalVisibility = React.createContext()

const Layout = ({ children }) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [modalSubmitted, setModalSubmitted] = useState({
    visible: false,
    message: null,
  })

  return (
      <ModalVisibility.Provider
        value={{
          modalVisibility,
          setModalVisibility,
          modalSubmitted,
          setModalSubmitted,
        }}
      >
        <div className="layout-wrapper">
          <Header />
          {children}
          <Modal />
          {modalSubmitted.visible && <ModalSubmitted />}
        </div>
      </ModalVisibility.Provider>
  )
}

export default Layout
