import React from "react"
import NavigationItem from "./NavigationItem"
import { ImFacebook } from "@react-icons/all-files/im/ImFacebook"
import { ImGoogle } from "@react-icons/all-files/im/ImGoogle"

const staticLinks = [
  {
    id: "01l",
    type: "normal",
    reference: "home",
    url: "/",
  },
  {
    id: "02l",
    type: "normal",
    reference: "usługi",
    url: "/uslugi",
    subLinks: [
      {
        reference: "koparko-ładowarka",
        url: "/uslugi/koparko-ladowarka",
      },
      {
        reference: "spawanie",
        url: "/uslugi/spawanie",
      },
      {
        reference: "hydraulika siłowa",
        url: "/uslugi/hydraulika-silowa",
      },
    ],
  },
  {
    id: "03l",
    type: "normal",
    reference: "kontakt",
    url: "/kontakt",
  },
  {
    id: "04l",
    type: "social",
    reference: <ImFacebook />,
    url: "https://www.facebook.com/Hydromat-Mateusz-Baran-105253371851221",
  },
  {
    id: "05l",
    type: "social",
    reference: <ImGoogle />,
    url:
      "https://www.google.pl/maps/place/Hydromat+Mateusz+Baran+-+Hydraulika+si%C5%82owa+-+Us%C5%82ugi+%C5%82adowarko-koparki+-+Us%C5%82ugi+spawalnicze/@52.3816504,15.7041662,17z/data=!4m12!1m6!3m5!1s0x47068b2398c5e187:0x35efbe9bbccac4f4!2sHydromat+Mateusz+Baran+-+Hydraulika+si%C5%82owa+-+Us%C5%82ugi+%C5%82adowarko-koparki+-+Us%C5%82ugi+spawalnicze!8m2!3d52.3816782!4d15.7063239!3m4!1s0x47068b2398c5e187:0x35efbe9bbccac4f4!8m2!3d52.3816782!4d15.7063239?hl=pl",
  },
]

const NavigationList = () => {
  const generateLinks = staticLinks.map(link => (
    <NavigationItem
      key={link.id}
      type={link.type}
      reference={link.reference}
      url={link.url}
      subLinks={link.subLinks}
    />
  ))

  return <ol className="navbar-navigation">{generateLinks}</ol>
}

export default NavigationList
