import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({ lang, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`Hydromat Mateusz Baran`}
    >
      <link rel="icon" type="image/png" href="../images/hydromat-logo.png"/>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content="Świadczymy usługi w zakresie hydrauliki siłowej, usług koparko-ładowarki oraz usług spawalniczych i ślusarskich na terenie województwa lubuskiego. Oferujemy usługi mobilne oraz stacjonarne."
      />
      <meta name="theme-color" content="#004792" />
      <meta name="robots" content="index" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Świadczymy usługi w zakresie hydrauliki siłowej, usług koparko-ładowarki oraz usług spawalniczych i ślusarskich na terenie województwa lubuskiego. Oferujemy usługi mobilne oraz stacjonarne."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.hydro-mat.com.pl/" />
      <meta property="og:image" content="https://www.hydro-mat.com.pl/static/313ecd5f1666af3910e136571bdea1be/f26c5/hydromat-logo.webp" />
      <meta name="author" content="WebsiteAB" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  description: `Hydraulika siłowa - Usługi ładowarko-koparki - Usługi spawalnicze - Hydromat Mateusz Baran`,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
