import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const Services = () => {
  return <StaticQuery
    query={graphql`
      query {
        hydromat {
          services {
            id
            name
            description
            slug
          }
        }
      }
    `}
    render={data => (
      <>
        {data.hydromat.services.map(item => (
        <li className="navbar-navigation-item" key={item.id}>
        <Link to={`/uslugi/${item.slug}`}>{item.name.substr(7)}</Link>
      </li>
      ))}
      </>
    )}
  />
}

export default Services
