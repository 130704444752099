import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Tobar = ({setAnimation, type, animation}) => {

  return (
      <div className="topbar">  
        <div className="topbar-brand">
          <Link to='/'><StaticImage src='../../images/hydromat-logo.png' alt='logo hydromat' /></Link>
        </div>
        <button className={`topbar-menu-button  topbar-menu-button--${type}`} aria-label="Close" onClick={() => setAnimation(!animation)}>
          <div className="menu-button-inner"></div>
        </button>
      </div>
  )
}

export default Tobar