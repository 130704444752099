import React, {useState, useEffect} from "react"
import Topbar from './Topbar'
import Navbar from './Navbar'

const Header = () => {
  const [animation, setAnimation] = useState(false);
  const [currentAnimationType, setCurrentAnimationType] = useState('');

  useEffect(() => {
    if(animation) {
      setCurrentAnimationType('active')
    } else{
      setCurrentAnimationType('close');
    }
  }, [animation]);
  
  return (
    <header>
      <Topbar setAnimation={setAnimation} type={currentAnimationType} animation={animation}/>
      <Navbar type={currentAnimationType} />
    </header>
  )
}

export default Header
