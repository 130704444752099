import React from "react"
import NavigationList from "./NavigationList"

const Tobar = ({ type }) => {
  return (
    <nav className={`navbar navbar--${type}`}>
      <NavigationList />
      <footer><small>Wszelkie prawa zastrzeżone Hydromat - Mateusz Baran {String.fromCharCode(169)}Copyright 2019-{new Date().getFullYear()}<br />
      Made by: <a aria-label='Fanpage autora strony' href="https://www.facebook.com/WebsiteAB-104439048525721/" target="_blank" rel="noopener noreferrer">WebsiteAB</a></small></footer>
    </nav>
  )
}

export default Tobar
