import React from "react"
import { Link } from "gatsby"
import ExtendedList from "./ExtendedList"

const NavigationItem = ({ subLinks, reference, url, type }) => {
  if (subLinks) {
    return <ExtendedList subLinks={subLinks} reference={reference} url={url} />
  }

  if (type === "social") {
    return (
      <li className="navbar-navigation-item">
        <a aria-label='social media' href={url} target='_blank' rel="noopener noreferrer">{reference}</a>
      </li>
    )
  }

  return (
    <li className="navbar-navigation-item">
      <Link to={url}>{reference}</Link>
    </li>
  )
}

export default NavigationItem
